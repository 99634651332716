import "./assets/main.css";

import { createApp } from "vue";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";

import App from "./App.vue";
import router from "./router";

const app = createApp(App);

// Initialize Sentry
Sentry.init({
  app,
  dsn: "https://31118c3bfc17380601764ea835cc4b56@o4507766920642560.ingest.us.sentry.io/4508253989437440",
  integrations: [],
});

app.use(createPinia());
app.use(router);

app.mount("#app");
