import { defineStore } from "pinia";
import * as Realm from "realm-web";

import { useCrudStore, useAuthStore, useInvestmentStore, useUIStore } from "@/stores";
import { parseDocumentsFieldsToProperType, applySchema } from "@/utilities";
import { JOINT_TEMPLATE, ENTITY_TEMPLATE } from "@/constants";

const {
  BSON: { ObjectId },
} = Realm;

export const useInvestorStore = defineStore("investorStore", {
  state: () => ({
    contact: null,
    account: null,
    allContacts: [],
    active_joint_contact: null,
    joint_contacts: [],
    beneficial_owner_contacts: [],
    isOwnerBeneficialOwner: false,
    selectedBeneficiary: null,
    isEditingBeneficiary: false,
    tempBeneficialOwners: [], // used for adding beneficial owners to a new entity not yet saved in the DB
    active_entity_account: null,
    entity_accounts: [],
    accreditation_documents: [],
    general_documents: [],
    isSignupBannerShowing: false,
    active_profile_tab: "Personal",
    active_documents_tab: "Documents",
    reg_d_acknowledgements: [],
    // banking drawer
    bank_accounts: [],
    active_bank_account: null,
    active_finance_method: null,
  }),
  getters: {
    doesActiveJointHaveSignedInvestments: (state) => {
      const investmentStore = useInvestmentStore();
      if (!state.active_joint_contact || !investmentStore.transactionsData) return false;
      return investmentStore.transactionsData
        .filter((inv) => inv.type === "Joint")
        .some((inv) => {
          return inv?.joint_contact_id?.toString() === state.active_joint_contact?._id?.toString();
        });
    },
    doesActiveEntityHaveSignedInvestments: (state) => {
      const investmentStore = useInvestmentStore();
      if (!state.active_entity_account || !investmentStore.transactionsData) return false;
      return investmentStore.transactionsData
        .filter((inv) => inv.type === "Entity")
        .some((inv) => {
          return (
            inv?.entity_account_id?.toString() === state.active_entity_account?._id?.toString()
          );
        });
    },
  },
  actions: {
    async setInvestorData() {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      try {
        const documentPipeline = [
          {
            $match: {
              user_id: authStore.currentUser.id,
              type: { $in: ["General Documents", "Accreditation"] },
            },
          },
          { $sort: { created_date: -1 } },
        ];
        const [allContacts, allAccounts, allDocuments] = await Promise.all([
          crudStore.find("Contacts", { user_id: authStore.currentUser.id }),
          crudStore.find("Accounts", { user_id: authStore.currentUser.id }),
          crudStore.aggregate("Documents", documentPipeline),
        ]);

        if (!allContacts || !allAccounts) {
          window.location.reload();
        }

        this.allContacts = parseDocumentsFieldsToProperType(allContacts);
        this.allAccounts = parseDocumentsFieldsToProperType(allAccounts);
        const ownerContact = this.allContacts.find((contact) => contact.type == "Regular");
        if (ownerContact) {
          this.contact = ownerContact;
          if (
            this.contact?.signup_questionnaire &&
            Object.values(this.contact?.signup_questionnaire).some((x) => !x)
          ) {
            this.isSignupBannerShowing = true;
          }
        }

        const allJointContacts = this.allContacts.filter((contact) => contact.type === "Joint");
        if (allJointContacts) {
          this.joint_contacts = allJointContacts;
          this.active_joint_contact = this.joint_contacts[0] || JOINT_TEMPLATE;
        }

        if (allAccounts) {
          const regularAccount = this.allAccounts.find((account) => account.type === "Regular");
          this.account = regularAccount;

          const entityAccounts = this.allAccounts.filter((account) => account.type === "Entity");
          if (entityAccounts) {
            this.entity_accounts = entityAccounts;
            this.active_entity_account = this.entity_accounts[0] || ENTITY_TEMPLATE;
          }
        }

        if (this.active_entity_account) {
          const allBeneficialOwnerContacts = this.allContacts.filter((contact) => {
            const stringEntityIds = contact?.entity_ids?.map((id) => id.toString());
            return (
              contact.type !== "Regular" &&
              stringEntityIds?.includes(this.active_entity_account?._id?.toString())
            );
          });
          if (allBeneficialOwnerContacts) {
            this.beneficial_owner_contacts = allBeneficialOwnerContacts;
          }
        }

        if (allDocuments) {
          this.general_documents = allDocuments.filter(
            (doc) => doc.type === "General Documents" || doc.type === "Accreditation"
          );
          this.accreditation_documents = allDocuments.filter((doc) => doc.type === "Accreditation");
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateContactAndAccount() {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const investmentStore = useInvestmentStore();
      try {
        if (!investmentStore.all_contact_schema) {
          investmentStore.all_contact_schema = await crudStore.fetchSchemaForCollection("Contacts");
        }

        const formattedOwnerContact = applySchema(this.contact, investmentStore.all_contact_schema);
        const account_query = { user_id: authStore.currentUser.id, type: "Regular" };
        const account_update = {
          name: `${formattedOwnerContact.first_name} ${formattedOwnerContact.last_name}`,
          email: formattedOwnerContact.email,
          phone: formattedOwnerContact.phone,
          street1: formattedOwnerContact.street1,
          street2: formattedOwnerContact.street2,
          city: formattedOwnerContact.city,
          state: formattedOwnerContact.state,
          zip: formattedOwnerContact.zip,
        };

        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${this.contact.first_name} ${this.contact.last_name}`,
        };
        const owner_contact_query = { user_id: authStore.currentUser.id, type: "Regular" };
        await crudStore.updateOne("Contacts", owner_contact_query, {
          $set: { ...formattedOwnerContact, ...updatedTimestamp },
        });
        await crudStore.updateOne("Accounts", account_query, {
          $set: { ...account_update, ...updatedTimestamp },
        });
      } catch (err) {
        console.error(err);
      }
    },
    async upsertJointContact(jointContact) {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const investmentStore = useInvestmentStore();

      try {
        if (!investmentStore.all_contact_schema) {
          investmentStore.all_contact_schema = await crudStore.fetchSchemaForCollection("Contacts");
        }

        const formattedJointContact = applySchema(jointContact, investmentStore.all_contact_schema);
        formattedJointContact.user_id = authStore.currentUser.id;
        formattedJointContact.account_id = this.account._id;
        formattedJointContact.contact_id = this.contact._id;

        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${this.contact.first_name} ${this.contact.last_name}`,
        };

        if (jointContact._id) {
          // Update existing joint contact
          const joint_contact_query = { _id: jointContact._id };
          await crudStore.updateOne("Contacts", joint_contact_query, {
            $set: { ...formattedJointContact, ...updatedTimestamp },
          });
        } else {
          // Insert new joint contact
          const newJointContact = {
            ...formattedJointContact,
            created_date: new Date(),
            created_by_id: authStore.currentUser.id,
            created_by_name: `${this.contact.first_name} ${this.contact.last_name}`,
            ...updatedTimestamp,
          };
          delete newJointContact.is_new;
          const { insertedId } = await crudStore.insertOne("Contacts", newJointContact);
          newJointContact._id = insertedId;
          const [parsedJointContact] = parseDocumentsFieldsToProperType([newJointContact]);
          this.allContacts.push(parsedJointContact);
          this.joint_contacts.push(parsedJointContact);
          this.active_joint_contact = parsedJointContact;
          return parsedJointContact;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async upsertEntityAccount(entityAccount) {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const investmentStore = useInvestmentStore();

      try {
        if (!investmentStore.all_account_schema) {
          investmentStore.all_contact_schema = await crudStore.fetchSchemaForCollection("Accounts");
        }

        const formattedEntityAccount = applySchema(
          entityAccount,
          investmentStore.all_account_schema
        );
        formattedEntityAccount.user_id = authStore.currentUser.id;
        formattedEntityAccount.account_id = this.account._id;
        formattedEntityAccount.contact_id = this.contact._id;

        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${this.contact.first_name} ${this.contact.last_name}`,
        };

        if (entityAccount?._id) {
          // Update existing entity account
          const entity_account_query = { _id: entityAccount._id };
          await crudStore.updateOne("Accounts", entity_account_query, {
            $set: { ...formattedEntityAccount, ...updatedTimestamp },
          });

          // Update owner contacts entity_ids based on if they are also a beneficial owner
          const owner_contact_query = { _id: this.contact._id };
          let currentEntityIds = new Set(this.contact?.entity_ids.map((id) => id.toString()) || []);

          // If the owner is a beneficial owner, add the entity_id to the set (as string)
          if (this.isOwnerBeneficialOwner) {
            currentEntityIds.add(this.active_entity_account._id.toString());
          } else {
            currentEntityIds.delete(this.active_entity_account._id.toString());
          }

          // Convert back to ObjectId before updating in MongoDB
          const updatedEntityIds = [...currentEntityIds].map((id) => new ObjectId(id));

          // Only perform the update if the entity_ids array has actually changed
          if (
            updatedEntityIds.length !== this.contact.entity_ids.length ||
            !updatedEntityIds.every(
              (id, index) => id.toString() === this.contact.entity_ids[index].toString()
            )
          ) {
            await crudStore.updateOne("Contacts", owner_contact_query, {
              $set: { entity_ids: updatedEntityIds },
            });
          }
        } else {
          // This is a new entity w/ possibly unsaved beneficial owners
          const newEntityAccount = {
            ...formattedEntityAccount,
            created_date: new Date(),
            created_by_id: authStore.currentUser.id,
            created_by_name: `${this.contact.first_name} ${this.contact.last_name}`,
            ...updatedTimestamp,
          };
          delete newEntityAccount.is_new;
          delete newEntityAccount.uuid;

          // Insert new entity account
          const { insertedId } = await crudStore.insertOne("Accounts", newEntityAccount);

          // Update owner contacts entity_ids based on if they are also a beneficial owner
          const owner_contact_query = { _id: this.contact._id };
          let currentEntityIds = new Set(
            this.contact?.entity_ids?.map((id) => id.toString()) || []
          );

          if (this.isOwnerBeneficialOwner) {
            currentEntityIds.add(insertedId.toString());
            const updatedEntityIds = [...currentEntityIds].map((id) => new ObjectId(id));
            await crudStore.updateOne("Contacts", owner_contact_query, {
              $set: { entity_ids: updatedEntityIds },
            });
          }

          // add the new entity account to local state
          newEntityAccount._id = insertedId;
          const [parsedEntityAccount] = parseDocumentsFieldsToProperType([newEntityAccount]);
          this.entity_accounts.push(parsedEntityAccount);
          this.active_entity_account = parsedEntityAccount;

          for (const beneficialOwner of this.tempBeneficialOwners) {
            await this.upsertBeneficialOwner(beneficialOwner);
          }
          return parsedEntityAccount;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async upsertBeneficialOwner(beneficialOwner) {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const investmentStore = useInvestmentStore();

      try {
        if (!investmentStore.all_contact_schema) {
          investmentStore.all_contact_schema = await crudStore.fetchSchemaForCollection("Contacts");
        }

        const formattedBeneficialOwner = applySchema(
          beneficialOwner,
          investmentStore.all_contact_schema
        );
        formattedBeneficialOwner.user_id = authStore.currentUser.id;
        formattedBeneficialOwner.account_id = this.account._id;
        formattedBeneficialOwner.contact_id = this.contact._id;
        // Combine existing entity_ids with the current active entity account's ID and remove duplicates
        const existingEntityIds = beneficialOwner.entity_ids || [];
        formattedBeneficialOwner.entity_ids = Array.from(
          new Set([...existingEntityIds, this.active_entity_account._id])
        );
        formattedBeneficialOwner.is_beneficial_owner = true;

        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${this.contact.first_name} ${this.contact.last_name}`,
        };

        if (beneficialOwner._id) {
          // Update existing joint contact
          const beneficial_owner_query = { _id: beneficialOwner._id };
          await crudStore.updateOne("Contacts", beneficial_owner_query, {
            $set: { ...formattedBeneficialOwner, ...updatedTimestamp },
          });
          // find contact in allContacts and update their entity_ids
          const contactIndex = this.allContacts.findIndex(
            (contact) => contact._id.toString() === beneficialOwner._id.toString()
          );
          if (contactIndex > -1) {
            this.allContacts[contactIndex].entity_ids = formattedBeneficialOwner.entity_ids;
          }
        } else {
          // Insert new joint contact
          const newBeneficialOwnerContact = {
            ...formattedBeneficialOwner,
            entity_ids: [ObjectId(this.active_entity_account._id)],
            created_date: new Date(),
            created_by_id: authStore.currentUser.id,
            created_by_name: `${this.contact.first_name} ${this.contact.last_name}`,
            ...updatedTimestamp,
          };
          delete newBeneficialOwnerContact.is_new;
          const { insertedId } = await crudStore.insertOne("Contacts", newBeneficialOwnerContact);
          newBeneficialOwnerContact._id = insertedId;
          const [parsedBeneficialOwner] = parseDocumentsFieldsToProperType([
            newBeneficialOwnerContact,
          ]);
          this.allContacts.push(parsedBeneficialOwner);
          this.beneficial_owner_contacts.push(parsedBeneficialOwner);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateBeneficialOwnerEntityIds(beneficialOwner, entityAccount) {
      const crudStore = useCrudStore();
      try {
        const beneficial_owner_query = { _id: ObjectId(beneficialOwner._id) };
        const newEntityIds = beneficialOwner.entity_ids.filter(
          (id) => id.toString() !== entityAccount._id?.toString()
        );
        await crudStore.updateOne("Contacts", beneficial_owner_query, {
          $set: { entity_ids: newEntityIds },
        });
      } catch (err) {
        console.error(err);
      }
    },
    async deleteBeneficialOwner(beneficialOwner) {
      const crudStore = useCrudStore();
      try {
        const contact_query = { _id: beneficialOwner._id };
        await crudStore.deleteOne("Contacts", contact_query);
      } catch (err) {
        console.error(err);
      }
    },
    async updateContactSuitabilityInfo() {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const investmentStore = useInvestmentStore();
      try {
        if (!investmentStore.all_contact_schema) {
          investmentStore.all_contact_schema = await crudStore.fetchSchemaForCollection("Contacts");
        }

        const formattedContact = applySchema(this.contact, investmentStore.all_contact_schema);
        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${this.contact.first_name} ${this.contact.last_name}`,
        };
        const query = { user_id: authStore.currentUser.id, type: "Regular" };
        const update = {
          $set: { suitability_info: formattedContact.suitability_info, ...updatedTimestamp },
        };
        await crudStore.updateOne("Contacts", query, update);
      } catch (err) {
        console.error(err);
      }
    },
    async updateRegDAcknowledgements(type, entity = null) {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      try {
        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${this.contact.first_name} ${this.contact.last_name}`,
        };
        if (type === "entity" && entity) {
          const query = { _id: entity._id };
          const update = {
            $set: {
              reg_d_entity_acknowledgements: this.reg_d_acknowledgements,
              ...updatedTimestamp,
            },
          };
          await crudStore.updateOne("Accounts", query, update);
        } else {
          const query = { user_id: authStore.currentUser.id, type: "Regular" };
          const update = {
            $set: {
              reg_d_personal_acknowledgements: this.reg_d_acknowledgements,
              ...updatedTimestamp,
            },
          };
          await crudStore.updateOne("Contacts", query, update);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async upsertSignupQuestionnaire() {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      try {
        const query = { user_id: authStore.currentUser.id, type: "Regular" };
        const update = {
          $set: { signup_questionnaire: authStore.signUpFields.signup_questionnaire },
        };
        await crudStore.updateOne("Contacts", query, update);
        this.contact.signup_questionnaire = authStore.signUpFields.signup_questionnaire;
        this.isSignupBannerShowing = false;
      } catch (err) {
        console.error(err);
      }
    },
    async updateCurrentStep(step) {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      try {
        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${this.contact.first_name} ${this.contact.last_name}`,
        };
        const query = { user_id: authStore.currentUser.id, type: "Regular" };
        const update = {
          $set: { current_step: step, ...updatedTimestamp },
        };
        await crudStore.updateOne("Contacts", query, update);
      } catch (err) {
        console.error(err);
      }
    },
    async fetchGeneralDocuments() {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const UIStore = useUIStore();

      try {
        const documentPipeline = [
          {
            $match: {
              user_id: authStore.currentUser.id,
              type: { $in: ["General Documents", "Accreditation"] },
            },
          },
          { $sort: { [UIStore.sortHeader.field_name]: UIStore.sortAscending ? 1 : -1 } },
        ];
        this.general_documents = await crudStore.aggregate("Documents", documentPipeline);
      } catch (err) {
        console.error(err);
      }
    },
    async updateGeneralDocument(document) {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();

      try {
        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${this.contact.first_name} ${this.contact.last_name}`,
        };
        const query = { _id: ObjectId(document._id) };
        const update = { $set: { ...document, ...updatedTimestamp } };
        await crudStore.updateOne("Documents", query, update);
      } catch (err) {
        console.error(err);
      }
    },
    async fetchBankAccounts(account_id) {
      const crudStore = useCrudStore();
      try {
        const bankAccounts = await crudStore.find("BankAccounts", { account_id: account_id });
        this.bank_accounts = bankAccounts;
      } catch (err) {
        console.error(err);
      }
    },
  },
});
